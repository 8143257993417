import { Field } from "formik"
import React, { useEffect, useState } from "react"
import classNames from "classnames"
import DatePickerField from "./DatePickerField"
import usePrevious from "./usePrevious"

const MgcDateField = ({
  name,
  placeholder,
  error,
  withoutOffset,
  setFieldValue,
  disabled,
  isErrorSubmitting,
  minDate,
  maxDate,
  currentDate,
  isValidating,
  resilierMutuelleActuelle
}) => {

  const [errorActive, setErrorActive] = useState(null)
  const [iconError, setIconError] = useState(false)
  const previousIsValidating = usePrevious(isValidating)
  const [resilierMutuelleActuelleNewValue, setResilierMutuelleActuelleNewValue] = useState(resilierMutuelleActuelle)

  useEffect(() => {
    if (errorActive && currentDate) {
      setErrorActive(null)
      setIconError(false)
    }
  }, [currentDate])

  useEffect(() => {
   if (previousIsValidating === true && isValidating === false && error) {
      setErrorActive(error)
      setIconError(true)
    }
  }, [isValidating])

  useEffect(() => {
      setResilierMutuelleActuelleNewValue(resilierMutuelleActuelle)
  }, [resilierMutuelleActuelle])

  const OffessetClassNames = classNames("form-group ", {
    "col-10 offset-1": !withoutOffset,
    "col-11": withoutOffset,
  })
  const checkerClassNames = classNames("fa fa-2x", {
    "fa-times text-danger": iconError,
    "fa-check text-success": !iconError && isErrorSubmitting,
  })
  const invalidFeedbackClassNames = classNames("invalid-feedback col-12 pl-0", {
    "d-block": errorActive,
  })

  return (
    <div className="form-row">
      <div className={OffessetClassNames}>
        <Field
          component={DatePickerField}
          className="form-control input-field"
          name={name}
          currentDate={currentDate}
          minDate={minDate}
          maxDate={maxDate}
          placeholder={placeholder}
          disabled={disabled}
          setFieldValue={setFieldValue}
          isErrorSubmitting={isErrorSubmitting}
          sendError={errorMessage => {
            setIconError(true)
            setErrorActive(errorMessage)
          }}
        />
          {
              (name !== 'dateEcheance') && <div className={invalidFeedbackClassNames}>{errorActive}</div>
          }
          {
              (name === 'dateEcheance' && currentDate === null && resilierMutuelleActuelleNewValue === true) && <div className='invalid-feedback col-12 pl-0 d-block'>Champs obligatoire</div>
          }
      </div>
      <div className="col-1">
          {  (name !== 'dateEcheance') && <span className={checkerClassNames} /> }
          {  (name === 'dateEcheance' && currentDate === null && resilierMutuelleActuelleNewValue === true) && <span className='fa fa-2x fa-times text-danger' /> }
      </div>
    </div>
  )
}

export default MgcDateField
