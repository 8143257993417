import React from 'react'
import DatePicker, { registerLocale } from "react-datepicker"
import fr from "date-fns/locale/fr"
import moment from "moment"
import "react-datepicker/dist/react-datepicker.css"
registerLocale("fr", fr)

const DatePickerField = ({
    field,
    setFieldValue,
    placeholder,
    minDate,
    maxDate,
    className,
    currentDate,
    sendError
}) => {
    function getFormattedDate(date) {
        if (date) {
            let day = date.getDate()
            day = day > 9 ? day : "0" + day
            let month = date.getMonth() + 1
            month = month > 9 ? month : "0" + month
            return date.getFullYear() + "-" + month + "-" + day
        } else {
            return null
        }
    }

    return (
        <div className="input-icons">
            <DatePicker
                dateFormat="dd / MM / yyyy"
                selected={currentDate ? new Date(currentDate) : null}
                onChange={d => {
                    const formattedDate = getFormattedDate(d)
                    setFieldValue(field.name, formattedDate)
                }}
                onCalendarClose={() => {
                    if (!currentDate && minDate && maxDate && field.name === 'dtDebut') {
                        sendError(`Veuillez saisir une date d'effet entre le ${moment(minDate).format("DD/MM/YYYY")} (date min) et le ${moment(maxDate).format("DD/MM/YYYY")} (date max).`)
                      }
                     else if (!currentDate && field.name === 'dateEcheance') {
                          sendError('Champs obligatoire')
                     }
                }}
                minDate={minDate ? new Date(minDate) : null}
                maxDate={maxDate ? new Date(maxDate) : null}
                disabledKeyboardNavigation
                placeholderText={placeholder}
                className={className}
                locale={fr}
            />
            <i className="far fa-calendar-alt icon" />
        </div>
    )
}

export default DatePickerField
